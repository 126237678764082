// Libraries
import { graphql } from 'gatsby'

// Custom
import {
  ErrorIconContainer,
  ErrorPageContainer,
  ErrorPageWrapper,
  ErrorSocialContainer,
  ErrorTextContainer,
  Icon,
  Text,
  Title,
} from './styled'
import { MainButtonLink } from 'components/UI/Buttons'
import { PaddingContainer } from 'components/UI/Containers'
import { SocialButton, SocialIcon } from 'components/UI/Images'
import { SOCIAL_ICONS } from 'data'

// Assets
import Ghost from 'images/icons/ghost.svg'
import Star from 'images/icons/starModal.svg'
import WrapProvider from 'WrapProvider'

const NotFoundPage = () => {
  const renderSocialButtons = () => {
    return SOCIAL_ICONS.map((socialMedia) => {
      return socialMedia.path === '#' ? null : (
        <SocialButton
          href={socialMedia.path}
          target="_blank"
          key={socialMedia.path}>
          <SocialIcon src={socialMedia.label} />
        </SocialButton>
      )
    })
  }

  return (
    <WrapProvider>
      <ErrorPageWrapper>
        <PaddingContainer>
          <ErrorPageContainer>
            <ErrorIconContainer>
              <Icon src={Star} />
              <Icon src={Ghost} />
              <Icon src={Star} />
            </ErrorIconContainer>
            <ErrorTextContainer>
              <Title>Ah, não!</Title>
              <Text>
                Parece que essa página não existe, mas não deixe isso parar
                você.
              </Text>
              <MainButtonLink to="/">Me leve para o Início</MainButtonLink>
            </ErrorTextContainer>
          </ErrorPageContainer>
        </PaddingContainer>
        <ErrorSocialContainer>{renderSocialButtons()}</ErrorSocialContainer>
      </ErrorPageWrapper>
    </WrapProvider>
  )
}

export default NotFoundPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
